import React, { useEffect, useState } from 'react';
import { Zap } from 'lucide-react';
import './AIFeedback.css';

const AIFeedback = () => {
  const [recommendation, setRecommendation] = useState("Log your first session!");
  const [isLoading, setIsLoading] = useState(true);
  const [hasAppliedInsight, setHasAppliedInsight] = useState(false);

  useEffect(() => {
    const fetchRecommendation = async () => {
      try {
        const response = await fetch(`/api/user/recommendation`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setRecommendation(data.Recommendation);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRecommendation();
  }, []);

  // Reset insight availability every day
  useEffect(() => {
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    const timeUntilMidnight = midnight.getTime() - Date.now();

    const timer = setTimeout(() => {
      setHasAppliedInsight(false);
    }, timeUntilMidnight);

    return () => clearTimeout(timer);
  }, [hasAppliedInsight]);

  return (
    <div className="ai-insight-container">
      <div className="insight-content">
        {isLoading ? (
          <div className="loading-pulse">
            <div className="pulse-bars">
              <div></div><div></div><div></div>
            </div>
            <span>Analyzing your progress...</span>
          </div>
        ) : (
          <div className="recommendation-card">
            <div className="card-header">
              <Zap className="card-icon" size={20} />
              <span>10X Recommendation</span>
            </div>
            <p className="recommendation-text">{recommendation}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIFeedback;