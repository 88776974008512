import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from './ThemeContext';
import { useUser } from './components/users/UserContext';

// Component imports
import Pomodoro from './components/Pomodoro';
import Dashboard from './components/dashboard/Dashboard';
import AIFeedback from './components/AIFeedback';
import Setup from './components/Setup';
import FAQ from './components/FAQ';
import About from './components/About';
import Login from './components/users/Login';
import Logout from './components/users/Logout';
import Register from './components/users/Register';
import Footer from './components/footer/Footer';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';

// Styles
import './App.css';

const ThemeToggle = ({ theme, toggleTheme }) => (
  <button
    onClick={toggleTheme}
    className="theme-toggle"
    aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
  >
    <FontAwesomeIcon 
      icon={theme === 'light' ? faMoon : faSun} 
      size="2x" 
      color="#f0a500" 
    />
  </button>
);

const AIToggle = ({ isAIEnabled, toggleAI }) => (
  <div className="ai-toggle-container">
    <div className="ai-toggle-wrapper">
      <input
        type="checkbox"
        id="ai-toggle"
        className="ai-toggle-input"
        checked={isAIEnabled}
        onChange={toggleAI}
        aria-label="Toggle AI feedback"
      />
      <label htmlFor="ai-toggle" className="ai-toggle-label">
        <div className="toggle-track">
          <div className="toggle-indicator">
            <div className="checkmark">
              <svg viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="4" className="pulse-circle" />
                <circle cx="5" cy="5" r="2" className="core-circle" />
              </svg>
            </div>
          </div>
          <div className="toggle-text">
            <span className="text-off">AI</span>
            <span className="text-on">AI</span>
          </div>
        </div>
      </label>
    </div>
  </div>
);

const Navigation = ({ username, isAIEnabled, toggleAI, theme, toggleTheme }) => (
  <nav className="App-nav">
    <div className="nav-left">
      <Link to="/">Home</Link>
      <Link to="/setup">Setup</Link>
      <div className='dashboard-icon'>
        <Link to="/dashboard">Dashboard</Link>
      </div>
      <div className='aifeedback-icon'>
        <Link to="/aifeedback">AI Recommendation</Link>
      </div>
    </div>
    
    <div className="nav-center">
      <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
      {/* {username && <AIToggle isAIEnabled={isAIEnabled} toggleAI={toggleAI} />} */}
    </div>
    
    <div className="nav-right">
      {!username ? (
        <>
          <Link to="/login">Login</Link>
          <Link to="/register">Register</Link>
        </>
      ) : (
        <>
          <div className="welcome-message">Signed in: {username}</div>
          <Link to="/logout">Logout</Link>
        </>
      )}
    </div>
  </nav>
);

const App = () => {
  const { theme, toggleTheme } = useTheme();
  const { username } = useUser();
  const [workDuration, setWorkDuration] = useState(25 * 60);
  const [breakDuration, setBreakDuration] = useState(5 * 60);
  const [isAIEnabled, setIsAIEnabled] = useState(true);

  const handleWorkDurationChange = (newDuration) => setWorkDuration(newDuration);
  const handleBreakDurationChange = (newDuration) => setBreakDuration(newDuration);
  const toggleAI = () => setIsAIEnabled(prev => !prev);

  return (
    <Router>
      <div className={`App ${theme}`}>
        <Navigation 
          username={username} 
          isAIEnabled={isAIEnabled} 
          toggleAI={toggleAI}
          theme={theme}
          toggleTheme={toggleTheme}
        />
        {/* <Routes>
          <Route path="/aifeedback" element={
            <div>
              {username && isAIEnabled && <AIFeedback />}
            </div>
          } />
        </Routes> */}
        {/* <SubNavigation /> */}
        
        <main className="App-content">
          <Routes>
            <Route 
              path="/" 
              element={
                <Pomodoro 
                  workDuration={workDuration} 
                  breakDuration={breakDuration} 
                  username={username} 
                />
              } 
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/aifeedback" element={isAIEnabled ? <AIFeedback /> : null} />
            <Route 
              path="/setup" 
              element={
                <Setup 
                  onWorkChange={handleWorkDurationChange} 
                  onBreakChange={handleBreakDurationChange} 
                />
              } 
            />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;